// 文件信息
<template>
  <base-dialog
    :visible.sync="currentVisible"
    :showFooter="false"
    title="文件信息"
    class="pad20"
  >
    <div>
      <title-center name="查看"></title-center>
      <base-table
       :columns="columns"
          :showPage="false"
          :tableAttrs="{
            stripe: true,
            'max-height':'200'
          }"
          :dataSource.sync="infoData"
          :is-caculate-height="false"
      >
       <template slot="code" slot-scope="scope">
        {{scope.$index+1}}
      </template>
      </base-table>
    </div>
    <template slot="footer">
      <base-button
        label="关 闭"
        type="default"
        @click="currentVisible = false"
      ></base-button>
    </template>
  </base-dialog>
</template>
<script>
import baseDialog from '@/components/common/base-dialog/base-dialog.vue'
import BaseButton from '@/components/common/button/base-button/base-button.vue'
import BaseTable from '@/components/common/table/base-table/base-table.vue'
import TitleCenter from './title-center.vue'
import { infoData } from '../utils/seal-datails-config'
export default {
  components: { baseDialog, BaseButton, BaseTable, TitleCenter },
  name: 'detailDialog',
  props: {
    formInfoconfig: Array,
    detailInfo: Object,
    fileInfovisible: Boolean
  },
  data () {
    return {
      count: 0,
      infoData: []
    }
  },
  computed: {
    currentVisible: {
      get () {
        return this.fileInfovisible
      },
      set (val) {
        this.$emit('update:fileInfovisible', val)
      }
    },
    columns () {
      return infoData(this)
    }
  },
  methods: {
    // 修改值改变弹窗内容
    setCount () {
      this.count++
    }
  }
}
</script>
